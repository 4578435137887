@font-face {
    font-family: "Dina";
    src: url("./resources/font/dinaremasterii-01_1-webfont.woff2") format("woff2"),
    url("./resources/font/dinaremasterii-01_1-webfont.woff") format("woff"),
    url("./resources/font/DinaRemasterII-01.ttf") format("truetype");
}

.header {
    vertical-align: middle;
    font-family: "Dina", serif;
    font-size: 35px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.horizontalLine {
    padding-top: 0;
    margin-top: 0;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.noStyle {
    background-color: transparent;
}

.cardRow {
    justify-content: center;
    background: transparent;
}

.definedRowHeight {
    vertical-align: middle;
    height: 50px;
}

.outershell {
    background-color: #dcdee5;
}

.phantom {
    display: block;
    padding: 20px;
    height: 60px;
    width: 100%;
}