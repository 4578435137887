.fixed-border {
    background-color: #dcdee5;
    display: block;
    padding-top: 10px;
}
.Footer {
    background-color: transparent;
    align-content: center;
    position: fixed;
    padding: 20px;
    bottom: 0;
    height: 60px;
    width: 100%;
}

.buttonIcon {
    height: 30px;
    width: 30px;
}

.centerContent {
    text-align: center;
    align-content: center;
}

.a {
    background-color: transparent;
    margin: 5px;
}